import React from "react";
import { useParams } from "react-router-dom";
import CropSpecificForm from "./Form";

type Props = {};

const EditCropSpecific: React.FC = () => {
  const params = useParams();
  const plantingSpecificId = params.planting_specificId;
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title"> Edit Planting Specific</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <CropSpecificForm id={plantingSpecificId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCropSpecific;
