import React, { useState, useEffect } from "react";

import Footer from "../Footer/Footer";

import "./WaterSaving.css";

const WaterSaving: React.FC = () => {
  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <h1>Coming Soon!</h1>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WaterSaving;
