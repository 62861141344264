import React, { useState, useEffect, useRef } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import { Map as LeafletMap, LatLngTuple } from "leaflet";
import { getCurrentUser } from "../../services/auth.service";
import {
  getFarmers,
  getFields,
  getField,
  getSeasonSchedule,
  getHindcast,
  getToday,
} from "../../services/api.service";
import Footer from "../Footer/Footer";
import IFarmer from "../../types/farmer.type";
import IField from "../../types/field.type";
import IModelRunResult from "../../types/model_run_result.type";
import IForecast from "../../types/forecast.type";

import "./Home.css";

type Props = {};

const Home: React.FC = () => {
  let navigate: NavigateFunction = useNavigate();
  const [map, setMap] = useState<LeafletMap | null>(null);
  const [farmers, setFarmers] = useState<IFarmer[]>([]);
  const [fields, setFields] = useState<IField[]>([]);
  const [hindcasts, setHindCast] = useState<IModelRunResult[]>([]);
  const [season_schedules, setSeasonSchedule] = useState<IModelRunResult[]>([]);
  const [todayHindCast, setTodayHindCast] = useState<IModelRunResult | null>(
    null
  );
  const [coordinates, setCoordinates] = useState([]);
  const [forecast, setForecast] = useState<IForecast[] | null>(null);

  const selectFieldRef = useRef<HTMLSelectElement>(null);

  const currentUser = getCurrentUser();

  const handleFarmerChange = (event: any) => {
    const value = event.target.value;
    if (selectFieldRef.current != null && selectFieldRef.current.value != "") {
      selectFieldRef.current.value = "";
    }
    getFields(value).then(
      (response) => {
        setFields(response.data);
      },
      (error) => {}
    );
  };

  const handleFieldChange = (event: any) => {
    const value = event.target.value;
    setTodayHindCast(null);

    getField(value).then((response) => {
      setForecast(response.data.forecast);

      if (map) {
        if (response.data.kmlString) {
          map.setZoom(12);

          const field_latitude: LatLngTuple = [
            response.data.latitude,
            response.data.longitude,
          ];

          map.setView(field_latitude, 12, {
            animate: false,
          });

          map.setZoom(18);

          setCoordinates(
            JSON.parse(response.data.kmlString)["features"][0]["geometry"][
              "coordinates"
            ][0].map((row: any) => [row[1], row[0]])
          );
        }
      }
    });

    getToday(value).then(
      (response) => {
        if (response.data.advisedIrrigationTime) {
          setTodayHindCast(response.data);
        }
      },
      (error) => {}
    );

    getSeasonSchedule(value).then(
      (response) => {
        setSeasonSchedule(response.data);
      },
      (error) => {}
    );

    getHindcast(value).then(
      (response) => {
        setHindCast(response.data);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }

    if (currentUser) {
      if (currentUser.userType == "irrigation_engineer") {
        getFarmers(currentUser.profileId).then(
          (response) => {
            setFarmers(response.data);
          },
          (error) => {}
        );
      }

      if (currentUser.userType == "farmer") {
        getFields(currentUser.profileId).then(
          (response) => {
            setFields(response.data);
          },
          (error) => {}
        );
      }
    }
  }, []);

  return (
    <div className="content-page">
      <div className="content">
        <div className="row">
          <div className="col-12">
            <div
              style={{
                position: "absolute",
                width: 800,
                height: 600,
                top: 10,
                right: 20,
                zIndex: 1000,
              }}
            >
              <div className="row g-2 mb-2">
                {currentUser &&
                  currentUser.userType == "irrigation_engineer" && (
                    <div className="col-md">
                      <select
                        className="form-select"
                        id="selectFarmer"
                        aria-label="Select Farmer"
                        onChange={handleFarmerChange}
                      >
                        <option selected disabled>
                          Select Farmer
                        </option>
                        {farmers.map((farmer) => (
                          <option key={farmer.id} value={farmer.id}>
                            {farmer.user.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                <div className="col-md">
                  <select
                    ref={selectFieldRef}
                    className="form-select"
                    id="selectField"
                    aria-label="Select Field"
                    onChange={handleFieldChange}
                  >
                    <option value="" selected disabled>
                      Select Field
                    </option>
                    {fields.map((field) => (
                      <option key={field.id} value={field.id}>
                        {field.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-xl">
                  <div className="card">
                    <div className="d-flex card-header justify-content-between align-items-center">
                      <h4 className="header-title">Seasonal Schedule</h4>
                      <div className="card-widgets">
                        <a
                          data-bs-toggle="collapse"
                          href="#seasonalCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="seasonalCollapse"
                        >
                          <i className="mdi mdi-minus"></i>
                        </a>
                        <a
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="The seasonal crop schedule helps to determine irrigation needs based on factors like location, crop type, irrigation system dimensions and field size. It uses historical data of the last ten years on evaporation and crop-specific factors to estimate averaged irrigation minutes. It can be compared to hindcasted schedules for validation."
                        >
                          <i className="mdi mdi-information-outline"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body pt-2 collapse show"
                      id="seasonalCollapse"
                    >
                      <div
                        className="table-responsive"
                        style={{
                          height: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <table
                          id="basic-datatable"
                          className="table table-sm table-centered mb-0 font-14"
                          width="100%"
                        >
                          <col style={{ width: "40%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Evap. [mm/d]</th>
                              <th>Water Volume [m3]</th>
                              <th>Irr. Time [min]</th>
                            </tr>
                          </thead>

                          <tbody>
                            {season_schedules.map((season_schedule) => (
                              <tr key={season_schedule.id}>
                                <td>{season_schedule.dateDisplay}</td>
                                <td>{season_schedule.evaporation}</td>
                                <td>{season_schedule.advisedWaterVolume}</td>
                                <td>{season_schedule.advisedIrrigationTime}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="card">
                    <div className="d-flex card-header justify-content-between align-items-center">
                      <h4 className="header-title">Hindcast</h4>
                      <div className="card-widgets">
                        <a
                          data-bs-toggle="collapse"
                          href="#hindcastCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="hindcastCollapse"
                        >
                          <i className="mdi mdi-minus"></i>
                        </a>
                        <a
                          href="#tootip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="The hindcasted crop schedule shows the irrigation schedule from the last 7 days. It addresses the uncertainties caused by climate change by incorporating more recent data from both the Climate Forecasting System Version 2 (CFSv2) and local TAHMO weather stations. TAHMO data is used when a weather station is within 35 km of the farmer's location, while CFSv2 data is utilized otherwise."
                        >
                          <i className="mdi mdi-information-outline"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body pt-2 collapse show"
                      id="hindcastCollapse"
                    >
                      <div
                        className="table-responsive"
                        style={{
                          height: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        <table
                          id="basic-datatable"
                          className="table table-sm table-centered mb-0 font-14"
                          width="100%"
                        >
                          <col style={{ width: "40%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <col style={{ width: "20%" }} />
                          <thead>
                            <tr>
                              <th>Day</th>
                              <th>Evap. [mm/d]</th>
                              <th>Water Volume [m3]</th>
                              <th>Irr. Time [min]</th>
                            </tr>
                          </thead>

                          <tbody>
                            {hindcasts.map((hindcast) => (
                              <tr key={hindcast.id}>
                                <td>{hindcast.dateDisplay}</td>
                                <td>{hindcast.evaporation}</td>
                                <td>{hindcast.advisedWaterVolume}</td>
                                <td>{hindcast.advisedIrrigationTime}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xl">
                  <div className="card">
                    <div className="d-flex card-header justify-content-between align-items-center">
                      <h4 className="header-title">Weather Forecast</h4>
                      <div className="card-widgets">
                        <a
                          data-bs-toggle="collapse"
                          href="#weatherTodayCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="weatherTodayCollapse"
                        >
                          <i className="mdi mdi-minus"></i>
                        </a>
                        <a
                          href="#tootip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="Weather Today"
                        >
                          <i className="mdi mdi-information-outline"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body pt-2 collapse show"
                      id="weatherTodayCollapse"
                    >
                      <div
                        className="table-responsive"
                        style={{
                          height: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {forecast && (
                          <table
                            id="basic-datatable"
                            className="table table-sm table-borderless table-centered mb-0 font-14"
                            width="100%"
                          >
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <col style={{ width: "25%" }} />
                            <thead>
                              <tr>
                                <th></th>
                                <th>{forecast[0].day}</th>
                                <th>{forecast[1].day}</th>
                                <th>{forecast[2].day}</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr key="1">
                                <td>
                                  <i
                                    className="mdi mdi-weather-pouring"
                                    style={{ fontSize: 45 }}
                                  ></i>
                                </td>
                                <td className="font-17">
                                  {forecast[0].precipitation} MM
                                </td>
                                <td className="font-17">
                                  {forecast[1].precipitation} MM
                                </td>
                                <td className="font-17">
                                  {forecast[2].precipitation} MM
                                </td>
                              </tr>
                              <tr key="1">
                                <td>
                                  <i
                                    className="mdi mdi-thermometer"
                                    style={{ fontSize: 45 }}
                                  ></i>
                                </td>
                                <td className="font-17">
                                  {forecast[0].temperature} &#8451;
                                </td>
                                <td className="font-17">
                                  {forecast[1].temperature} &#8451;
                                </td>
                                <td className="font-17">
                                  {forecast[2].temperature} &#8451;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="card">
                    <div className="d-flex card-header justify-content-between align-items-center">
                      <h4 className="header-title">Irrigation Today</h4>
                      <div className="card-widgets">
                        <a
                          data-bs-toggle="collapse"
                          href="#irrigationTodayCollapse"
                          role="button"
                          aria-expanded="false"
                          aria-controls="irrigationTodayCollapse"
                        >
                          <i className="mdi mdi-minus"></i>
                        </a>
                        <a
                          href="#tootip"
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          data-bs-title="The irrigation advice for today is based on the weather of yesterday. With the irrigation minutes of today, you will replenish the soil to restore the water that was evaporated from the soil and the plant yesterday."
                        >
                          <i className="mdi mdi-information-outline"></i>
                        </a>
                      </div>
                    </div>
                    <div
                      className="card-body pt-2 collapse show"
                      id="irrigationTodayCollapse"
                    >
                      <div
                        className="table-responsive"
                        style={{
                          height: 200,
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                      >
                        {todayHindCast && (
                          <div className="d-flex">
                            <div className="flex-shrink-0">
                              <i
                                className="mdi mdi-watering-can"
                                style={{ fontSize: 45 }}
                              ></i>
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h4 className="mt-0 mb-1 font-20">
                                {" "}
                                Irrigation Min Today
                              </h4>
                              <h4 className="my-2">
                                {todayHindCast.advisedIrrigationTime} MIN
                              </h4>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <MapContainer
              center={[6.671519247, -1.670523365]}
              zoom={17}
              scrollWheelZoom={false}
              ref={setMap}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // maxZoom={20}
                subdomains={["mt1", "mt2", "mt3"]}
              />
              {/* <Marker position={[51.505, -0.09]}>
                <Popup>
                  
                </Popup>
              </Marker> */}
              <Polygon color={"red"} positions={coordinates} />
            </MapContainer>

            {/* end card*/}
          </div>{" "}
        </div>
        {/* end col*/}
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Home;
