import React, { useState, useEffect } from "react";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Select from "react-dropdown-select";
import { getCurrentUser } from "../../services/auth.service";
import {
  postFarmer,
  getFarmer,
  updateFarmer,
  getEngineers,
} from "../../services/api.service";
import * as Yup from "yup";

import IUser from "../../types/user.type";
import IFarmer from "../../types/farmer.type";
import IEngineer from "../../types/engineer.type";

type Props = {
  id?: string;
};

const FarmerForm: React.FC<Props> = ({ id }) => {
  const currentUser = getCurrentUser();
  const [irrigation_engineers, setIrrigationEngineers] = useState<{}[]>([]);
  const [existingEngineers, setExistingEngineers] = useState<{}[]>([]);
  const [selectedEngineers, setSelectedEngineers] = useState<string[]>([]);

  let navigate: NavigateFunction = useNavigate();
  const [farmer, setFarmer] = useState<IFarmer>({
    user: { userType: "farmer" },
    irrigationEngineers: [],
    messageTime: "",
    isControlFarmer: false,
  });

  useEffect(() => {
    if (id) {
      getFarmer(id).then((response) => {
        setFarmer(response.data);
        for (const farmerIrrigation of response.data.irrigationEngineers) {
          setExistingEngineers((prevIrrigationEngineerData) => {
            return [
              ...prevIrrigationEngineerData,
              { value: farmerIrrigation.id, label: farmerIrrigation.name },
            ];
          });
        }
      });
    }

    getEngineers(currentUser.organisation).then((response) => {
      for (const engineer_data of response.data) {
        setIrrigationEngineers((prevEngineerData) => {
          return [
            ...prevEngineerData,
            { value: engineer_data.id, label: engineer_data.name },
          ];
        });
      }
    });
  }, []);

  const initialValues: IFarmer = farmer;

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      name: Yup.string().required("This field is required!"),
      phone: Yup.string()
        .required("This field is required!")
        .matches(phoneRegExp, "Phone number is not valid"),
      email: Yup.string().required("This field is required!"),
      password: Yup.string().when("$id", (id, schema) =>
        id ? schema : schema.required("This field is required!")
      ),
    }),
    messageTime: Yup.string().required("This field is required!"),
  });

  const handleChange = (values: {}[]) => {
    setSelectedEngineers([]);
    values.forEach((selected_engineer_option: any, index) => {
      setSelectedEngineers((prevSelectedEngineer) => {
        return [...prevSelectedEngineer, selected_engineer_option.value];
      });
    });
  };

  const handleSubmit = (formValue: any) => {
    formValue.irrigationEngineers = selectedEngineers;
    if (id) {
      updateFarmer(id, formValue).then((response) => {
        if (response.statusText == "OK") {
          navigate("/farmer", {
            state: {
              message: "Farmer's Account Has been updated Successfully",
              color: "info",
            },
          });
        }
      });
    } else {
      postFarmer(formValue).then(
        (response) => {
          if (response.statusText == "OK") {
            navigate("/farmer", {
              state: {
                message: "Farmer's Account Has been Created Successfully",
                color: "success",
              },
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form className="needs-validation" noValidate>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">
            Name &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Add the full name of your farmer here"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="name"
            type="text"
            name="user.name"
          />
          <ErrorMessage
            name="user.name"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="phone" className="form-label">
            Phone Number &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Add the phone number of the farmer, with country code."
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="phone"
            type="text"
            name="user.phone"
          />
          <ErrorMessage
            name="user.phone"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emailaddress" className="form-label">
            Email address &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Enter the e-mail address of the farmer so they can log in to the
              dashboard."
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            name="user.email"
            type="email"
            id="emailaddress"
            placeholder="Enter your email"
          />
          <ErrorMessage
            name="user.email"
            component="div"
            className="invalid-feedback"
          />
        </div>

        <div className="mb-3">
          <label htmlFor="password" className="form-label">
            Password &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Password can’t be too similar to your other personal information.&nbsp; Password must contain at least 8 characters.&nbsp;  Password can’t be a commonly used password. &nbsp; Your password can’t be entirely numeric."
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <div className="input-group input-group-merge">
            <Field
              type="password"
              name="user.password"
              id="password"
              className="form-control"
              placeholder="Enter your password"
            />
            <div className="input-group-text" data-password="false">
              <span className="password-eye"></span>
            </div>
            <ErrorMessage
              name="user.password"
              component="div"
              className="invalid-feedback"
            />
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor="engineers" className="form-label">
            Engineers &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Select here which engineers need to be able to see and/or edit the information for this specific farmer"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Select
            multi
            name="user.irrigationEngineers"
            className="basic-multi-select"
            values={existingEngineers}
            options={irrigation_engineers}
            onChange={(values) => handleChange(values)}
          ></Select>
        </div>
        <div className="mb-3">
          <label htmlFor="messageTime" className="form-label">
            Preferred message time (UTC)
          </label>
          <Field
            component="select"
            className="form-control"
            id="messageTime"
            name="messageTime"
          >
            <option selected disabled value="">
              Select Preferred message time (UTC)
            </option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </Field>
          <ErrorMessage
            name="messageTime"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3 form-check form-switch">
          <Field
            type="checkbox"
            className="form-check-input"
            id="isControlFarmer"
            name="isControlFarmer"
          ></Field>
          <label htmlFor="isControlFarmer" className="form-check-label">
            Is Control Farmer
          </label>

          <ErrorMessage
            name="isControlFarmer"
            component="div"
            className="invalid-feedback"
          />
        </div>

        <div className="mb-3 mb-0 text-center">
          <button className="btn btn-primary" type="submit">
            {id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default FarmerForm;
