import React, {useState, useEffect, useRef} from "react";
import {useNavigate, NavigateFunction, Link} from "react-router-dom";
import {Formik, Field, Form, ErrorMessage} from "formik";
import {getCurrentUser} from "../../services/auth.service";
import {
    getFarmers,
    getFields,
    getCropSpecific,
    postCropSpecifics,
    updateCropSpecifics,
} from "../../services/api.service";
import * as Yup from "yup";
import IFarmer from "../../types/farmer.type";
import IField from "../../types/field.type";
import ICropSpecific from "../../types/crop_specific.type";

type Props = {
    id?: string;
};

const CropSpecificForm: React.FC<Props> = ({id}) => {
    let navigate: NavigateFunction = useNavigate();
    const [farmers, setFarmers] = useState<IFarmer[]>([]);
    const [fields, setFields] = useState<IField[]>([]);
    const [crop_specific, setCropSecific] = useState<ICropSpecific>({});

    const selectFieldRef = useRef<HTMLSelectElement>(null);

    const currentUser = getCurrentUser();

    const handleFarmerChange = (event: any) => {
        const value = event.target.value;
        if (selectFieldRef.current != null && selectFieldRef.current.value != "") {
            selectFieldRef.current.value = "";
        }
        getFields(value).then(
            (response) => {
                setFields(response.data);
            },
            (error) => {
            }
        );
    };

    useEffect(() => {
        if (currentUser) {
            if (currentUser.userType == "irrigation_engineer") {
                getFarmers(currentUser.profileId).then(
                    (response) => {
                        setFarmers(response.data);
                    },
                    (error) => {
                    }
                );

                // getFarmers(currentUser.profileId).then(
                //   (response) => {
                //     for (const farmer of response.data) {
                //       getFields(farmer.id).then((fieldReponse) => {
                //         setFields((previousFields) => {
                //           return [...previousFields, ...fieldReponse.data];
                //         });
                //       });
                //     }
                //   },
                //   (error) => {}
                // );
            }

            if (currentUser.userType == "farmer") {
                getFields(currentUser.profileId).then(
                    (response) => {
                        setFields(response.data);
                    },
                    (error) => {
                    }
                );
            }

            if (id) {
                getCropSpecific(id).then((response) => {
                    setCropSecific(response.data);
                });
            }
        }
    }, []);

    const initialValues: ICropSpecific = crop_specific;

    const validationSchema = Yup.object().shape({
        field: Yup.string().required("This field is required!"),
        cropType: Yup.string().required("This field is required!"),
        plantingDate: Yup.string().required("This field is required!"),
        lastExpectedHarvestingDate: Yup.string().required(
            "This field is required!"
        ),
    });

    const handleSubmit = (formValue: {}) => {
        if (id) {
            updateCropSpecifics(id, formValue).then((response) => {
                if (response.statusText == "OK") {
                    navigate("/planting-specific", {
                        state: {
                            message: "Planting Specific Has been updated Successfully",
                            color: "info",
                        },
                    });
                }
            });
        } else {
            postCropSpecifics(formValue).then(
                (response) => {
                    if (response.statusText == "Created") {
                        navigate("/planting-specific", {
                            state: {
                                message: "Planting Specific Has been Created Successfully",
                                color: "success",
                            },
                        });
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
        >
            <Form className="needs-validation" noValidate>
                <div className="mb-3">
                    <label htmlFor="field" className="form-label">
                        Farmer
                    </label>
                    <Field
                        component="select"
                        className="form-control"
                        id="farmer"
                        name="farmer"
                        onChange={handleFarmerChange}
                    >
                        <option selected disabled value="">
                            Select farmer
                        </option>
                        {farmers.map((farmer) => (
                            <option key={farmer.id} value={farmer.id}>
                                {farmer.user.name}
                            </option>
                        ))}
                    </Field>
                    <ErrorMessage
                        name="field"
                        component="div"
                        className="invalid-feedback"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="field" className="form-label">
                        Field
                    </label>
                    <Field
                        component="select"
                        className="form-control"
                        id="field"
                        name="field"
                    >
                        <option selected disabled value="">
                            Select Field
                        </option>
                        {fields.map((field) => (
                            <option key={field.id} value={field.id}>
                                {field.name}
                            </option>
                        ))}
                    </Field>
                    <ErrorMessage
                        name="field"
                        component="div"
                        className="invalid-feedback"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="cropType" className="form-label">
                        Crop Type
                    </label>
                    <Field
                        component="select"
                        className="form-control"
                        id="cropType"
                        name="cropType"
                    >
                        <option selected disabled value="">
                            Select CropType
                        </option>
                        <option value="Habanero Peppers">Habanero Peppers</option>
                        <option value="French Beans">French Beans</option>
                        <option value="Onions">Onions</option>
                        <option value="lettuce">Lettuce</option>
                        <option value="Leafy Vegetables">Leafy Vegetables</option>
                        <option value="Fruity Vegetables">Fruity Vegetables</option>
                        <option value="Pulses">Pulses</option>
                        <option value="Brassica">Brassica</option>
                        <option value="Cucumbers">Cucumbers</option>
                        <option value="Tomatoes">Tomatoes</option>
                        <option value="African Eggplant">African Eggplant</option>
                        <option value="Okra">Okra</option>
                    </Field>
                    <ErrorMessage
                        name="cropType"
                        component="div"
                        className="invalid-feedback"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="planting-date" className="form-label">
                        Planting Date &nbsp;
                        <a
                            href="#tootip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-title="Enter the date the farmer planted the seeds."
                        >
                            <i className="mdi mdi-information-outline"></i>
                        </a>
                    </label>
                    <Field
                        className="form-control"
                        id="planting-date"
                        type="date"
                        name="plantingDate"
                    />
                    <ErrorMessage
                        name="plantingDate"
                        component="div"
                        className="invalid-feedback"
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="harvesting-date" className="form-label">
                        Last Expected Harvesting Date &nbsp;
                        <a
                            href="#tootip"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            data-bs-title="Enter the last date you expect the farmer to harvest crops."
                        >
                            <i className="mdi mdi-information-outline"></i>
                        </a>
                    </label>
                    <Field
                        className="form-control"
                        id="harvesting-date"
                        type="date"
                        name="lastExpectedHarvestingDate"
                    />
                    <ErrorMessage
                        name="lastExpectedHarvestingDate"
                        component="div"
                        className="invalid-feedback"
                    />
                </div>
                <div className="mb-3 mb-0 text-center">
                    <button className="btn btn-primary" type="submit">
                        {id ? "Update" : "Submit"}
                    </button>
                </div>
            </Form>
        </Formik>
    );
};

export default CropSpecificForm;
