import React, { useState, useEffect } from "react";
import {
  useSearchParams,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { login, getCurrentUser } from "../../services/auth.service";

import Footer from "../Footer/Footer";

import "./Login.css";

type Props = {};

const Login: React.FC<Props> = () => {
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const [searchParams, setSearchParams] = useSearchParams();

  const initialValues: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  };

  useEffect(() => {
    const currentUser = getCurrentUser();
    const email = searchParams.get("email");
    const password = searchParams.get("password");

    if (email && password) {
      login(email, password)
        .then(
          () => {
            navigate("/");
            window.location.reload();
          },

          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.detail) ||
              error.message ||
              error.toString();

            setSearchParams();
            console.log(resMessage);
            console.log(email);
            console.log(password);

            setLoading(false);
            setMessage(resMessage);
          }
        )
        .catch((err) => {});
    }

    if (currentUser) {
      navigate("/");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const handleLogin = (formValue: { email: string; password: string }) => {
    const { email, password } = formValue;

    setMessage("");
    setLoading(true);

    login(email, password)
      .then(
        () => {
          navigate("/");
          window.location.reload();
        },

        (error) => {
          console.log(error.response);
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.detail) ||
            error.message ||
            error.toString();
          console.log(resMessage);

          setLoading(false);
          setMessage(resMessage);
        }
      )
      .catch((err) => {});
  };

  return (
    <div>
      {message && (
        <div>
          <div
            className="alert alert-danger alert-dismissible text-bg-danger border-0 fade show"
            role="alert"
          >
            <button
              type="button"
              className="btn-close btn-close-white"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
            <strong>Error - </strong> Wrong Email and Password Combination
          </div>
        </div>
      )}
      <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-5">
              <div className="card">
                {/* Logo */}
                <div className="card-header py-4 text-center bg-primary">
                  <a href="#">
                    <span>
                      <img
                        src="assets/images/logo.png"
                        alt="logo"
                        width="200px"
                        height="auto"
                      />
                    </span>
                  </a>
                </div>
                <div className="card-body p-4">
                  <div className="text-center w-75 m-auto">
                    <h4 className="text-dark-50 text-center pb-0 fw-bold">
                      Sign In
                    </h4>
                    <p className="text-muted mb-4">
                      Enter your email address and password to access admin
                      panel.
                    </p>
                  </div>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleLogin}
                  >
                    <Form
                      className="needs-validation"
                      autoComplete="off"
                      noValidate
                    >
                      <div className="mb-3">
                        <label htmlFor="emailaddress" className="form-label">
                          Email address
                        </label>
                        <Field
                          className="form-control"
                          name="email"
                          type="email"
                          id="emailaddress"
                          placeholder="Enter your email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>

                      <div className="mb-3">
                        {/* <a href="" className="text-muted float-end">
                          <small>Forgot your password?</small>
                        </a> */}
                        <label htmlFor="password" className="form-label">
                          Password
                        </label>
                        <div className="input-group input-group-merge">
                          <Field
                            type="password"
                            name="password"
                            id="password"
                            className="form-control"
                            placeholder="Enter your password"
                          />
                          <div
                            className="input-group-text"
                            data-password="false"
                          >
                            <span className="password-eye"></span>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>

                      <div className="mb-3 mb-0 text-center">
                        <button className="btn btn-primary" type="submit">
                          {" "}
                          Log In{" "}
                        </button>
                      </div>
                    </Form>
                  </Formik>
                </div>{" "}
                {/* end card-body */}
              </div>
              {/* end card */}
            </div>{" "}
            {/* end col */}
          </div>
          {/* end row */}
        </div>
        {/* end container */}
      </div>
      <Footer />
    </div>
  );
};

export default Login;
