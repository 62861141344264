import React, { useState, useEffect } from "react";
import { useNavigate, NavigateFunction, useParams } from "react-router-dom";
import { getCurrentUser } from "../../services/auth.service";
import IField from "../../types/field.type";
import { getField } from "../../services/api.service";

import Footer from "../Footer/Footer";

import "./Field.css";

const ViewField: React.FC = () => {
  let navigate: NavigateFunction = useNavigate();
  const [field, setField] = useState<IField>({});
  const params = useParams();
  const fieldId = params.fieldId;

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (currentUser) {
      if (fieldId) {
        getField(fieldId).then((response) => {
          console.log(response);
          setField(response.data);
        });
      }
    }
  }, []);

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Field Detail</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-center">
                    <div className="col-12">
                      <div className="d-flex align-items-center">
                        <ul className="list-inline mb-0 text-muted">
                          <li className="list-inline-item">
                            Name: {field.name}
                          </li>
                          <li className="list-inline-item">
                            Name: {field.name}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ViewField;
