import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { getCurrentUser } from "../../services/auth.service";
import FieldForm from "./Form";

type Props = {};

const EditField: React.FC = () => {
  let navigate: NavigateFunction = useNavigate();

  const params = useParams();
  const fieldId = params.fieldId;

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }
  }, []);

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title"> Edit Field</h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <FieldForm id={fieldId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditField;
