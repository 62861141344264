import React, { useState, useEffect } from "react";
import { kml } from "@tmcw/togeojson";
import { MapContainer, TileLayer, Polygon } from "react-leaflet";
import { LatLng, Map as LeafletMap, polygon } from "leaflet";
import { useNavigate, NavigateFunction, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { getCurrentUser } from "../../services/auth.service";
import {
  getFarmers,
  getField,
  postField,
  updateField,
} from "../../services/api.service";
import * as Yup from "yup";

import IField from "../../types/field.type";
import IFarmer from "../../types/farmer.type";

type Props = {
  id?: string;
};

const FieldForm: React.FC<Props> = ({ id }) => {
  let navigate: NavigateFunction = useNavigate();
  const [farmers, setFarmers] = useState<IFarmer[]>([]);
  const [field, setField] = useState<IField>({});
  const [geoJson, setGeoJson] = useState<string>();
  const [latitude, setLatitude] = useState<string>();
  const [longitude, setLongitude] = useState<string>();

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (currentUser) {
      if (currentUser.userType == "irrigation_engineer") {
        getFarmers(currentUser.profileId).then(
          (response) => {
            setFarmers((prevFarmers) => {
              return [...prevFarmers, ...response.data];
            });
          },
          (error) => {}
        );
      }

      if (id) {
        getField(id).then((response) => {
          setField(response.data);
        });
      }
    }
  }, []);

  const initialValues: IField = field;

  const validationSchema = Yup.object().shape({
    farmer: Yup.string().required("This field is required!"),
    name: Yup.string().required("This field is required!"),
    soilType: Yup.string().required("This field is required!"),
    waterSource: Yup.string().required("This field is required!"),
    irrigationType: Yup.string().required("This field is required!"),
    numberOfDriplines: Yup.string().required("This field is required!"),
    lengthOfDriplines: Yup.string().required("This field is required!"),
    bedWidth: Yup.string().required("This field is required!"),
    emitterSpacing: Yup.string().required("This field is required!"),
    emitterFlowRate: Yup.string().required("This field is required!"),
    initialisationTime: Yup.string().required("This field is required!"),
  });

  const handleSubmit = (formValue: any) => {
    if (geoJson) {
      formValue.kmlString = geoJson;
      formValue.latitude = latitude;
      formValue.longitude = longitude;
    }
    if (id) {
      updateField(id, formValue).then((response) => {
        if (response.statusText == "OK") {
          navigate("/fields", {
            state: {
              message: "Field Has been updated Successfully",
              color: "info",
            },
          });
        }
      });
    } else {
      postField(formValue).then(
        (response) => {
          if (response.statusText == "Created") {
            navigate("/fields", {
              state: {
                message: "Field Has been Created Successfully",
                color: "success",
              },
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  };

  const handleKMLUpload = (event: any) => {
    const reader = new FileReader();
    const selectedFile = event.currentTarget.files[0];
    reader.onload = (ev) => {
      const geojsonFile = kml(
        new DOMParser().parseFromString(reader.result as string, "text/xml")
      );

      const field_latitude: LatLng = polygon(
        JSON.parse(JSON.stringify(geojsonFile))["features"][0]["geometry"][
          "coordinates"
        ][0].map((row: any) => [row[1], row[0]])
      )
        .getBounds()
        .getCenter();
      setLatitude(field_latitude.lat.toString());
      setLongitude(field_latitude.lng.toString());
      setGeoJson(JSON.stringify(geojsonFile));
    };
    reader.readAsText(selectedFile, "text/xml");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      <Form className="needs-validation">
        <div className="mb-3">
          <label htmlFor="field" className="form-label">
            Farmer
          </label>
          <Field
            component="select"
            className="form-control"
            id="farmer"
            name="farmer"
          >
            <option selected disabled value="">
              Select Farmer
            </option>
            {farmers.map((farmer) => (
              <option key={farmer.id} value={farmer.id}>
                {farmer.user.name}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="farmer"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="field-name" className="form-label">
            Field Name &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Pick a field name for this specific location, unrelated to the crop 
              type (because of crop rotation) that will enable you to distinguish between the 
              different fields of a farmer (for example: demo field)."
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="field-name"
            type="text"
            name="name"
          />
          <ErrorMessage
            name="name"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="soilType" className="form-label">
            Soil Type
          </label>
          <Field
            component="select"
            className="form-control"
            id="soilType"
            name="soilType"
          >
            <option selected disabled value="">
              Select Soil Type
            </option>
            <option value="sandy_loam">Sandy Loam</option>
            <option value="medium">Medium</option>
            <option value="clay">Clay</option>
          </Field>
          <ErrorMessage
            name="soilType"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="waterSource" className="form-label">
            Water Source
          </label>
          <Field
            component="select"
            className="form-control"
            id="waterSource"
            name="waterSource"
          >
            <option selected disabled value="">
              Select Water Source
            </option>
            <option value="pump">Pump</option>
            <option value="borehole">Borehole</option>
            <option value="reservoir">Reservoir</option>
          </Field>
          <ErrorMessage
            name="waterSource"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="irrigationType" className="form-label">
            Irrigation Type
          </label>
          <Field
            component="select"
            className="form-control"
            id="irrigationType"
            name="irrigationType"
          >
            <option selected disabled value="">
              Select Irrigation Type
            </option>
            <option value="drip">Drip</option>
          </Field>
          <ErrorMessage
            name="irrigationType"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="numberOfDriplines" className="form-label">
            Number Of Driplines &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Amount of driplines used for the field."
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="numberOfDriplines"
            type="number"
            name="numberOfDriplines"
          />
          <ErrorMessage
            name="numberOfDriplines"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lengthOfDriplines" className="form-label">
            Length Of Driplines [m] &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Total length of 1 dripline"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="lengthOfDriplines"
            type="number"
            name="lengthOfDriplines"
          />
          <ErrorMessage
            name="lengthOfDriplines"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="bedWidth" className="form-label">
            Bed Width [m] &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Enter the effective bed width (is the bed width without a possible
                footpath taken into account)"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="bedWidth"
            type="number"
            name="bedWidth"
          />
          <ErrorMessage
            name="bedWidth"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emitterSpacing" className="form-label">
            Emitter Spacing [m] &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Width between two emitters"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="emitterSpacing"
            type="number"
            name="emitterSpacing"
          />
          <ErrorMessage
            name="emitterSpacing"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="emitterFlowRate" className="form-label">
            Emitter Flow Rate [m3/s] &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Amount of water that flows through each emitter in m3/s. Check the manual to see how to measure this"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="emitterFlowRate"
            type="number"
            name="emitterFlowRate"
          />
          <ErrorMessage
            name="emitterFlowRate"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="initialisationTime" className="form-label">
            Initialisation Time [min] &nbsp;
            <a
              href="#tootip"
              data-bs-toggle="tooltip"
              data-bs-placement="right"
              data-bs-title="Time from the start of the irrigation until the system in 90% filled. See the manual on how to measure this"
            >
              <i className="mdi mdi-information-outline"></i>
            </a>
          </label>
          <Field
            className="form-control"
            id="initialisationTime"
            type="number"
            name="initialisationTime"
          />
          <ErrorMessage
            name="initialisationTime"
            component="div"
            className="invalid-feedback"
          />
        </div>
        <div className="mb-3">
          <label htmlFor="kmlfile" className="form-label">
            KML File
          </label>
          <input
            id="file"
            name="file"
            type="file"
            className="form-control"
            onChange={(event) => {
              handleKMLUpload(event);
            }}
          />
        </div>

        <div className="mb-3 mb-0 text-center">
          <button className="btn btn-primary" type="submit">
            {id ? "Update" : "Submit"}
          </button>
        </div>
      </Form>
    </Formik>
  );
};

export default FieldForm;
