import axios from 'axios';
import authHeader from './auth-header';
import ICropSpecific from '../types/crop_specific.type';
import IField from '../types/field.type';
import IFarmer from '../types/farmer.type';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export const getEngineers = (organisation: string) => {
    return axios.get(API_URL + 'engineers/?organisation=' + organisation, {headers: authHeader()});
}

export const getFields = (farmer: string) => {
    return axios.get(API_URL + 'fields/?farmer=' + farmer, {headers: authHeader()});
}

export const getField = (id: string) => {
    return axios.get(API_URL + `fields/${id}/`, {headers: authHeader()});
}

export const postField = (field_data: IField) => {
    return axios.post(API_URL + 'fields/', field_data, {headers: authHeader()})
}

export const updateField = (id: string, field_data: IField) => {
    return axios.put(API_URL + `fields/${id}/`, field_data, {headers: authHeader()})
}

export const deleteField = (id: string) => {
    return axios.delete(API_URL + `fields/${id}/`, {headers: authHeader()});
}

export const getFarmers = (engineer: string) => {
    return axios.get(API_URL + 'farmers/?irrigation_engineers=' + engineer, {headers: authHeader()});
}

export const getFarmer = (id:string) => {
    return axios.get(API_URL + `farmers/${id}/`, {headers: authHeader()});
}

export const postFarmer = (farmer_data: IFarmer) => {
    return axios.post(API_URL + 'farmers/', farmer_data, {headers: authHeader()})
}

export const updateFarmer = (id: string, farmer_data: IFarmer) => {
    return axios.put(API_URL + `farmers/${id}/`, farmer_data, {headers: authHeader()})
}

export const deleteFarmer = (id: string) => {
    return axios.delete(API_URL + `farmers/${id}/`, {headers: authHeader()});
}

export const getHindcast = (field: string | null) => {
    if (field) {
        return axios.get(API_URL + 'hindcast/?limit=5&field=' + field, {headers: authHeader()});
    } else {
        return axios.get(API_URL + 'hindcast/?limit=5', {headers: authHeader()});

    }
}

export const getSeasonSchedule = (field: string | null) => {
    if (field) {
        return axios.get(API_URL + 'seasonal_schedule/?limit=5&field=' + field, {headers: authHeader()});
    } else {
        return axios.get(API_URL + 'seasonal_schedule/?limit=5', {headers: authHeader()});

    }
}

export const getToday = (field: string | null) => {
    return axios.get(API_URL + 'hindcast/today/' + field, {headers: authHeader()});
}

export const getCropSpecifics = (field: string) => {
    return axios.get(API_URL + 'crop-specifics/?field=' + field, {headers: authHeader()});

}

export const getCropSpecific = (id: string) => {
    return axios.get(API_URL + `crop-specifics/${id}/`, {headers: authHeader()});
}

export const postCropSpecifics = (crop_specific_data: ICropSpecific) => {
    return axios.post(API_URL + 'crop-specifics/', crop_specific_data, {headers: authHeader()})
}

export const updateCropSpecifics = (id: string, crop_specific_data: ICropSpecific) => {
    return axios.put(API_URL + `crop-specifics/${id}/`, crop_specific_data, {headers: authHeader()})
}

export const deleteCropspecific = (id: string) => {
    return axios.delete(API_URL + `crop-specifics/${id}/`, {headers: authHeader()})
}


