import React from "react";
import { Link } from "react-router-dom";
import { getCurrentUser } from "../../services/auth.service";
import "./Sidebar.css";

const Sidebar: React.FC = () => {
  const currentUser = getCurrentUser();

  return (
    <div className="leftside-menu">
      {/* Brand Logo Light */}
      {/* <a href="/" className="logo logo-light">
        <span className="logo-lg">
          <img
            src="assets/images/logo.png"
            alt="logo"
            width="200px"
            height="auto"
          />
        </span>
        <span className="logo-sm">
          <img src="assets/images/logo-sm.png" alt="small logo" />
        </span>
      </a> */}

      {/* Brand Logo Dark */}
      {/* <a href="/" className="logo logo-dark">
        <span className="logo-lg">
          <img src="assets/images/logo-dark.png" alt="dark logo" />
        </span>
        <span className="logo-sm">
          <img src="assets/images/logo-dark-sm.png" alt="small logo" />
        </span>
      </a> */}

      {/* Sidebar Hover Menu Toggle Button */}
      <div
        className="button-sm-hover"
        data-bs-toggle="tooltip"
        data-bs-placement="right"
        title="Show Full Sidebar"
      >
        <i className="ri-checkbox-blank-circle-line align-middle"></i>
      </div>

      {/* Full Sidebar Menu Close Button */}
      <div className="button-close-fullsidebar">
        <i className="ri-close-fill align-middle"></i>
      </div>

      {/* Sidebar -left */}
      <div
        className="h-100"
        style={{ marginTop: 35 }}
        id="leftside-menu-container"
        data-simplebar
      >
        {/* Leftbar User */}

        {/*- Sidemenu */}
        <ul className="side-nav">
          <li className="side-nav-title">Navigation</li>

          <li className="side-nav-item">
            <a href="/" className="side-nav-link">
              <i className="uil-calender"></i>
              <span> Home </span>
            </a>
          </li>

          {currentUser.userType == "irrigation_engineer" && (
            <li className="side-nav-item">
              <a href="/farmer" className="side-nav-link">
                <i className="uil-users-alt"></i>
                <span> Farmer </span>
              </a>
            </li>
          )}
          {currentUser.userType == "irrigation_engineer" && (
            <li className="side-nav-item">
              <a href="/fields" className="side-nav-link">
                <i className="uil-files-landscapes-alt"></i>
                <span> Field </span>
              </a>
            </li>
          )}
          <li className="side-nav-item">
            <a href="/planting-specific" className="side-nav-link">
              <i className="uil-crop-alt"></i>
              <span> Planting Specific </span>
            </a>
          </li>
          <li className="side-nav-item">
            <a href="/water-saving" className="side-nav-link">
              <i className="uil-water-glass"></i>
              <span> Water Saving </span>
            </a>
          </li>
        </ul>
        {/*- End Sidemenu */}

        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default Sidebar;
