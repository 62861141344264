import React from "react";

type Props = {};

const Footer: React.FC<Props> = () => {
  return (
    <footer className="footer footer-alt">
      2023 - <script>document.write(new Date().getFullYear())</script> © Sosia +
    </footer>
  );
};

export default Footer;
