import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";

import { getCurrentUser, logout } from "./services/auth.service";
import IUser from "./types/user.type";

import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Sidebar from "./components/Sidebar/Sidebar";

import Farmer from "./components/Farmer/Farmer";
import AddFarmer from "./components/Farmer/Add";
import EditFarmer from "./components/Farmer/Edit";

import CropSpecific from "./components/CropSpecific/CropSpecific";
import AddCropSpecific from "./components/CropSpecific/Add";
import EditCropSpecific from "./components/CropSpecific/Edit";

import Field from "./components/Field/Field";
import AddField from "./components/Field/Add";
import EditField from "./components/Field/Edit";
import ViewField from "./components/Field/View";

import WaterSaving from "./components/WaterSaving/WaterSaving";

import EventBus from "./common/EventBus";

import "./App.css";

type Props = {};

const App: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const user = getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, []);

  const logOut = () => {
    logout();
    setCurrentUser(undefined);
  };

  return (
    <div className="wrapper">
      {currentUser && (
        <div>
          <Navbar />
          <Sidebar />
        </div>
      )}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/farmer" element={<Farmer />} />
        <Route path="/farmer/add" element={<AddFarmer />} />
        <Route path="/farmer/:farmerId/edit" element={<EditFarmer />} />

        <Route path="/planting-specific" element={<CropSpecific />} />
        <Route path="/planting-specific/add" element={<AddCropSpecific />} />
        <Route
          path="/planting-specific/:planting_specificId/edit"
          element={<EditCropSpecific />}
        />
        <Route path="/fields" element={<Field />} />
        <Route path="/fields/:fieldId" element={<ViewField />} />
        <Route path="/fields/add" element={<AddField />} />
        <Route path="/fields/:fieldId/edit" element={<EditField />} />
        <Route path="/water-saving" element={<WaterSaving />} />
      </Routes>
    </div>
  );
};

export default App;
