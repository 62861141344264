import axios from "axios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}auth/`;

export const login = (email: string, password: string) => {
  return axios
    .post(API_URL + "login/", {
      email,
      password,
    }, {headers:
      {'Content-Type': 'application/json', 'Accept': 'application/json'}})
    .then((response) => {
      if (response.data.access) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

export const logout = () => {
  localStorage.removeItem("user");
};

export const getCurrentUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);

  return null;
};