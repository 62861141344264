import React, { useState, useEffect } from "react";
import { getCurrentUser, logout } from "../../services/auth.service";
import IUser from "../../types/user.type";
import EventBus from "../../common/EventBus";
import "./Navbar.css";

const Navbar: React.FC = () => {
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  useEffect(() => {
    const user = getCurrentUser();

    if (user) {
      setCurrentUser(user);
    }

    EventBus.on("logout", logOut);

    return () => {
      EventBus.remove("logout", logOut);
    };
  }, []);

  const logOut = () => {
    logout();
    setCurrentUser(undefined);
  };

  return (
    <div className="navbar-custom">
      <div className="topbar container-fluid">
        <div className="d-flex align-items-center gap-lg-2 gap-1">
          {/* Topbar Brand Logo */}
          <div className="logo-topbar">
            {/* Logo light */}
            {/* <a href="/" className="logo-light">
              <span className="logo-lg">
                <img src="assets/images/logo.png" alt="Sosia +" />
              </span>
              <span className="logo-sm">
                <img src="assets/images/logo-sm.png" alt="Sosia +" />
              </span>
            </a> */}

            {/* Logo Dark */}
            <a href="/" className="logo-dark">
              <span className="logo-lg">
                <img
                  src="assets/images/logo.png"
                  alt="Sosia +"
                  width="132px"
                  height="auto"
                  style={{ paddingTop: 10 }}
                />
              </span>
              <span className="logo-lg">
                <img
                  src="assets/images/partner_logo.png"
                  alt="Partner Voor Water"
                  width="108px"
                  height="auto"
                />
              </span>
              <span className="logo-lg">
                <img
                  src="assets/images/TUDELFTLOGO.png"
                  alt="TU DELFT"
                  width="108px"
                  height="auto"
                  style={{ paddingLeft: 15 }}
                />
              </span>
              <span className="logo-lg">
                <img
                  src="assets/images/TAHMOLOGO.png"
                  alt="TAHMO"
                  width="108px"
                  height="auto"
                  style={{ paddingLeft: 15 }}
                />
              </span>
              <span className="logo-lg">
                <img
                  src="assets/images/HGTLOGO.png"
                  alt="Holland GreenTech"
                  width="108px"
                  height="auto"
                  style={{ paddingLeft: 15 }}
                />
              </span>
              <span className="logo-lg">
                <img
                  src="assets/images/FWLOGO.jpg"
                  alt="Future Water"
                  width="108px"
                  height="auto"
                  style={{ paddingLeft: 15 }}
                />
              </span>
              {/* <span className="logo-sm">
                <img src="assets/images/logo.png" alt="Sosia +" />
              </span> */}
            </a>
          </div>

          {/* Sidebar Menu Toggle Button */}
          {/* <button className="button-toggle-menu">
            <i className="mdi mdi-menu"></i>
          </button> */}

          {/* Horizontal Menu Toggle Button */}
          <button
            className="navbar-toggle"
            data-bs-toggle="collapse"
            data-bs-target="#topnav-menu-content"
          >
            <div className="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>

          {/* Topbar Search Form */}
          <div className="app-search dropdown d-none d-lg-block">
            <div
              className="dropdown-menu dropdown-menu-animated dropdown-lg"
              id="search-dropdown"
            >
              {/* item*/}
            </div>
          </div>
        </div>

        <ul className="topbar-menu d-flex align-items-center gap-3">
          <li className="dropdown">
            <a
              className="nav-link dropdown-toggle arrow-none nav-user px-2"
              data-bs-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <span className="account-user-avatar">
                {/* <img
                  src="assets/images/users/avatar-1.jpg"
                  alt="user-image"
                  width="32"
                  className="rounded-circle"
                /> */}
              </span>
              <span className="d-lg-flex flex-column gap-1 d-none">
                <h5 className="my-0">{currentUser && currentUser.name}</h5>
                {/* <h6 className="my-0 fw-normal">
                  {currentUser && currentUser.userType}
                </h6> */}
              </span>
            </a>
            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
              {/* item*/}
              <div className=" dropdown-header noti-title">
                <h6 className="text-overflow m-0">Welcome !</h6>
              </div>

              {/* item*/}

              {/* item*/}
              <a href="/login" className="dropdown-item" onClick={logOut}>
                <i className="mdi mdi-logout me-1"></i>
                <span>Logout</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
