import React, { useState, useEffect } from "react";
import {
  useLocation,
  useNavigate,
  NavigateFunction,
  Link,
} from "react-router-dom";
import Swal from "sweetalert2";
import { getCurrentUser } from "../../services/auth.service";
import { getFarmers, deleteFarmer } from "../../services/api.service";
import IFarmer from "../../types/farmer.type";

import Footer from "../Footer/Footer";

import "./Farmer.css";

type Props = {};

const Farmer: React.FC = () => {
  let navigate: NavigateFunction = useNavigate();
  const [farmers, setFarmers] = useState<IFarmer[]>([]);
  const { state } = useLocation();
  const notification_message = state?.message;
  const notification_color = state?.color;
  window.history.replaceState({}, document.title);

  const currentUser = getCurrentUser();

  useEffect(() => {
    if (!currentUser) {
      navigate("/login");
    }

    getFarmers(currentUser.profileId).then(
      (response) => {
        setFarmers(response.data);
      },
      (error) => {}
    );
  }, []);

  const confirmDelete = (id: string) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteFarmer(id).then(() => {
          Swal.fire("Deleted!", "Field has been deleted.", "success").then(
            () => {
              window.location.reload();
            }
          );
        });
      }
    });
  };

  return (
    <div className="content-page">
      <div className="content">
        <div className="container-fluid">
          {notification_message && (
            <div className="row">
              <div
                className={`alert alert-${notification_color} alert-dismissible bg-${notification_color} text-white border-0 fade show`}
                role="alert"
              >
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></button>
                <strong>{notification_message}</strong>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">
                  Farmers
                  <Link to={"/farmer/add"} className="btn btn-info btn-sm ms-3">
                    Add New
                  </Link>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <table
                    id="basic-datatable"
                    className="table dt-responsive nowrap w-100"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th></th>
                      </tr>
                    </thead>

                    <tbody>
                      {farmers.map((farmer) => (
                        <tr>
                          <td>{farmer.user.name}</td>
                          <td>{farmer.user.phone}</td>
                          <td>{farmer.user.email}</td>
                          <td>
                            <Link to={`/farmer/${farmer.id}/edit`}>Edit</Link> |{" "}
                            <Link
                              to={"#"}
                              style={{ color: "red" }}
                              onClick={() => confirmDelete(farmer.id)}
                            >
                              Delete
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Farmer;
